$white: #ffffff;
$whiteSnow: #fdfdfd;
$black: #000000;
$bg-color: #000000;
$black-soft: #0B0B0D;
$accent: #3A849A;
$accent-lighter: #4799b2;
$red: #FF4646;
$violet: #B479FF;
$blue-dim: #3A849A;
$accent-blue: #2977D4;
$light-green: #3DFD91;
$light-pink: #FF1694;
$light-blue: #8AEDFF;
$light-yellow: #FFFB81;
$lilac: #DA47FF;
$blue: #6AD2FF;
$gray: #989898
