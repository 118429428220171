.info-page-button {
  position: absolute;
  width: 40px;
  height: 40px;
  top: 30px;
  right: 20px;
  background-color: $white;
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.info-page {
  position: absolute;
  bottom: -100vh;
  left: 0;
  margin-top: 10vh;
  height: 95vh;
  width: 100vw;
  background-color: $whiteSnow;
  display: flex;
  flex-direction: column;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.2);
  transition: bottom 0.3s ease-in-out;
  padding: 10px;
  justify-content: space-between;

  .info-page--close-button {
    width: 24px;
    height: 24px;
    margin-left: auto;
  }
  .info-page--title {
    color: $black-soft;
    font-family: "Roboto", sans-serif;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .info-page--container {
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: flex-start;
    justify-content: flex-start;
    margin-bottom: auto;

    overflow-y: auto;
    &::-webkit-scrollbar-thumb {
      display: none;
    }

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .info-page--text {
    font-family: "Roboto", sans-serif;
    color:  $black-soft;
    font-size: 18px;
  }

  .info-page--img {
    height: 75px;
    object-fit: cover;
  }

  .info-page--img-container {
    display: flex;
    flex-direction: row;
    gap: 10px;
  }

  .info-page--divider {
    width: calc(100vw - 20px);
    height: 1px;
    background-color: rgba($black-soft, 0.5);
    padding-left: 20px;
    padding-right: 20px;
    margin-top: 20px;
  }
}

.info-page.visible {
  bottom: 0;
}
