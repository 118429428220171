* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    background: var(--tg-theme-bg-color);
    color: var(--tg-theme-text-color);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background: linear-gradient(#010202, #6CCFD7);
}

button {
    background: none;
    border: none;
    outline: none;
    box-shadow: none;
}
.bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-size: cover;
    background-position: center;
    will-change: opacity;
}




.title {
    position: absolute;
    top: 100px;
    left: 0;
    color: white !important;
    font-size: 30px;
    z-index: 1000;
}
.back-btn {
    position: absolute;
    color: white !important;
    z-index: 1000;
    top: 10px;
    left: 0;
    font-size: 20px;
    width: 74px !important;
}
.btn-main {
    z-index: 0;
    position: relative;
}
