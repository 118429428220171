.footer-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  width: 100vw;
  height: 70px;
  background-color: $white;
  border-top: 1px solid #FFFFFF26;
  box-shadow: 0 4px 80px 0 #00000040;
  border-top: 1px solid #FFFFFF1A;
  z-index: 9900;
  cursor: pointer;
}

.footer__cards {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 15%;
  justify-content: center;
  cursor: pointer;
}

.footer__cards:hover .footer__icon {
    path {
      fill: $accent
     }
}
.footer__cards:hover .footer__text {
  color: $white;
}

.footer__cards.footer__cards--active .footer__icon {
  path {
    fill: $accent
  }
}
.footer__cards.footer__cards--active .footer__text {
  color: $white;
}

.footer__text {
  font-size: 12px;
  line-height: 18px;
  color: rgba($white, 0.6);
}


.disabled {
  pointer-events: none;
  opacity: 0.6;
}
